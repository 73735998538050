











import { computed, defineComponent } from "@vue/composition-api";
import useWatchPropsValue from "./useWatchPropsValue";

export default defineComponent({
  name: "BasicSelect",
  props: { fieldTitle: {type: String}, value: { type: String }, options: { type: Array}},
  setup(props, { emit }) {
    const { valueRef } = useWatchPropsValue(props);
    const computedValue = computed({
      get() {
        return valueRef.value ? valueRef.value.toString() : null;
      },
      set(value: string) {
        valueRef.value = value;
        const realValue = value === "" ? null : value;
        emit("input", realValue);
      },
    });

    return {
      // Computed
      computedValue,
    };
  },
});
